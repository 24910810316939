<template>
  <div class="container">
    <h1 class="text-black"></h1>
    <b-card>
    <b-row>
      <b-col md="12">
        <table class="table">
          <tbody v-if="isLoading">
            <tr>
              <td class="d-flex justify-content-center align-items-center">
              <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
            </td>
          </tr>
          </tbody>
          <tbody v-else>
            <tr><td>ID</td><td>{{ MonetizedStoreView.id }}</td></tr>
            <tr><td>Name (English)	</td><td>{{MonetizedStoreView.name}}</td></tr>
            <tr><td>Monetized Code	</td><td>{{ MonetizedStoreView.monetizedCode }}</td></tr>
            <tr><td>Monetized Link	</td><td>{{MonetizedStoreView.monetizedLink}}</td></tr>
            <tr><td>Non Monetized Code	</td><td>{{ MonetizedStoreView.nonMonetizedCoupons }}</td></tr>
            <tr><td>Non Monetized Link	</td><td>{{ MonetizedStoreView.nonMonetizedLinks }}</td></tr>
            <tr><td>Total Copy Count	</td><td>{{MonetizedStoreView.totalCopyCount}}</td></tr>
          </tbody>
        </table>
      </b-col>
      <b-col md="6">
        <h3>Monetized Code Detail</h3>
        <table class="table">
          <thead>
            <tr>
              <th>Coupon ID</th>
              <th>Coupon Code	</th>
              <th>Copy Count	</th>
              <th>Status</th>
              </tr>
          </thead> 
          <tbody v-if="isLoading">
            <tr>
              <td colspan="4">
                <div  class="d-flex justify-content-center align-items-center">
              <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
            </div>
            </td>
          </tr>
          </tbody>
          <tbody v-else-if="MonetizedCodeDetail.length">
            <tr v-for="(item,index) in MonetizedCodeDetail" :key="index">
              <td>{{item.couponId}}</td>
              <td>{{item.couponCode}}	</td>
              <td>{{item.copyCount}}</td>
              <td>{{item.status?'Active' : 'Inactive'}}</td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr>
              <td colspan="4" class="text-center"> No Data Found</td>
            </tr>
          </tbody>
        </table>
      </b-col>
      
      <b-col md="6">
        <h3>Non Monetized Code Detail</h3>
        <table class="table">
          <thead>
            <tr>
              <th>Coupon ID</th>
              <th>Coupon Code	</th>
              <th>Copy Count	</th>
              <th>Status</th>
              </tr>
          </thead>
          <tbody v-if="isLoading">
            <tr>
              <td colspan="4">
                <div  class="d-flex justify-content-center align-items-center">
              <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
            </div>
            </td>
          </tr>
          </tbody>
          <tbody v-else-if="NonMonetizedCodeDetail.length">
            <tr v-for="(item,index) in NonMonetizedCodeDetail" :key="index">
              <td>{{item.couponId}}</td>
              <td>{{item.couponCode}}	</td>
              <td>{{item.copyCount}}</td>
              <td>{{item.status?'Active' : 'Inactive'}}</td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr>
              <td colspan="4" class="text-center"> No Data Found</td>
            </tr>
          </tbody>
        </table>
      </b-col>
    
    <b-col md="6">
        <h3>Monetized Link Detail</h3>
        <table class="table">
          <thead>
            <tr>
              <th>Coupon ID</th>
              <th>Coupon Code	</th>
              <th>Copy Count	</th>
              <th>Status</th>
              </tr>
          </thead>
          <tbody v-if="isLoading">
            <tr>
              <td colspan="4">
                <div  class="d-flex justify-content-center align-items-center">
              <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
            </div>
            </td>
          </tr>
          </tbody>
          <tbody v-else-if="MonetizedLinkDetail.length">
            <tr v-for="(item,index) in MonetizedLinkDetail" :key="index">
              <td>{{item.couponId}}</td>
              <td>{{item.couponCode}}	</td>
              <td>{{item.copyCount}}</td>
              <td>{{item.status?'Active' : 'Inactive'}}</td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr>
              <td colspan="4" class="text-center"> No Data Found</td>
            </tr>
          </tbody>
        </table>
      </b-col>
      
      <b-col md="6">
        <h3>Non Monetized Link Detail</h3>
        <table class="table">
          <thead>
            <tr>
              <th>Coupon ID</th>
              <th>Coupon Code	</th>
              <th>Copy Count	</th>
              <th>Status</th>
              </tr>
          </thead>
          <tbody v-if="isLoading">
            <tr>
              <td colspan="4">
                <div  class="d-flex justify-content-center align-items-center">
              <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
            </div>
            </td>
          </tr>
          </tbody>
          <tbody v-else-if="NonMonetizedLinkDetail.length" >
            <tr v-for="(item,index) in NonMonetizedLinkDetail" :key="index">
              <td>{{item.couponId}}</td>
              <td>{{item.couponCode}}	</td>
              <td>{{item.copyCount}}</td>
              <td>{{item.status?'Active' : 'Inactive'}}</td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr>
              <td colspan="4" class="text-center"> No Data Found</td>
            </tr>
          </tbody>
        </table>
      </b-col>
      <b-col md="12">
        <b-button @click="$router.push('/monetization')" class="btn float-right btn-lg btn-primary">Back</b-button>
      </b-col>
    </b-row>
  </b-card>
  </div>
</template>
<script>


export default{
  components:{
  },
  mounted(){
    this.getGraphdata()
  },
  data(){
    return{
      baseURL: process.env.VUE_APP_BASE_URL,
      MonetizedStoreView:{},
      MonetizedCodeDetail:[],
      NonMonetizedCodeDetail:[],
      MonetizedLinkDetail:[],
      NonMonetizedLinkDetail:[],
      isLoading:false,
    }
  },
  methods:{
    async getGraphdata(){
      this.isLoading = true
       this.$http.post(`${this.baseURL}/client/view-chart/${this.$route.params.id}`,{app_id:this.$store.state.application.id}).then((json) => {
        this.MonetizedStoreView = {
          id:json.data.model.id,
          name:json.data.model.name_en,
          monetizedCode:json.data.json.monetizedCoupons.length,
          monetizedLink:json.data.json.monetizedLinks.length,
          nonMonetizedCoupons:json.data.json.nonMonetizedCoupons.length,
          nonMonetizedLinks:json.data.json.nonMonetizedLinks.length,
          totalCopyCount:json.data.copy_count
        }
        this.MonetizedCodeDetail = []
        json.data.json.monetizedCoupons.map((i,index) => {
          let obj ={
            couponId: i.id,
            couponCode:i.code,
            copyCount:json.data.monetizedCouponsData[index].copy_count,
            status:i.is_active
          }
          this.MonetizedCodeDetail.push(obj)
        })
        this.NonMonetizedCodeDetail = []
        json.data.json.nonMonetizedCoupons.map((i,index) => {
          let obj ={
            couponId: i.id,
            couponCode:i.code,
            copyCount:json.data.nonMonetizedCouponsData[index].copy_count,
            status:i.is_active
          }
          this.NonMonetizedCodeDetail.push(obj)
        })
        
        this.MonetizedLinkDetail = []
        json.data.json.monetizedLinks.map((i,index) => {
          let obj ={
            couponId: i.id,
            couponCode:i.code,
            copyCount:json.data.monetizedLinksData[index].copy_count,
            status:i.is_active
          }
          this.MonetizedLinkDetail.push(obj)
        })
        this.NonMonetizedLinkDetail = []
        json.data.json.nonMonetizedLinks.map((i,index) => {
          let obj ={
            couponId: i.id,
            couponCode:i.code,
            copyCount:json.data.nonMonetizedLinksData[index].copy_count,
            status:i.is_active
          }
          this.NonMonetizedLinkDetail.push(obj)
        })
      this.isLoading = false
      this.$forceUpdate();
        // console.log(this.MonetizedStoreView,this.MonetizedCodeDetail, this.NonMonetizedCodeDetail,this.MonetizedLinkDetail,this.NonMonetizedLinkDetail)
      })
    //    this.$http.get(`${this.baseURL}/client/get-pie-charts`).then((json) => {
    //     this.couponsTrend = json.data.couponsTrend
    //     this.storesTrend = json.data.storesTrend
    //     this.couponsTrendSum = this.sum(this.couponsTrend)
    //   })
    //     this.$http.get(`${this.baseURL}/client/get-monetized-graphs`).then((json) => {
    //     this.couponsMonetized = json.data.couponsMonetized
    //     this.linkMonetized = json.data.linkMonetized
    //   })
    },
    // sum(obj){
    //     let sum = 0;
    //   for (const key in obj) {
    //       // Check if the value is numeric (excluding strings)
    //       if (!isNaN(obj[key]) && typeof obj[key] !== "string") {
    //         sum += Number(obj[key]);
    //       }
    //     }
    //     return sum
    // }
  }
}
</script>